import React, { useEffect, useState } from "react";
import { Widget, addResponseMessage, toggleMsgLoader } from "react-chat-widget";
import { useQuery } from "react-query";

import { sendMessage } from "./Chat.helper";

import "react-chat-widget/lib/styles.css";
import { startChatEndpoint } from "../api/consts";

function Chat() {
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [userMessage, setUserMessage] = useState<string | null>(null);

  const { isFetching, isFetched, refetch, data } = useQuery(
    ["message", conversationId],
    () => {
      if (conversationId && userMessage)
        return sendMessage({
          conversation_id: conversationId,
          message: userMessage,
        });
    },
    { enabled: false }
  );

  const botReply = data?.reply;

  // Start chat
  useEffect(() => {
    if (process.env.REACT_APP_API_AUTHORIZATION_TOKEN) {
      fetch(startChatEndpoint, {
        headers: {
          Authorization: process.env.REACT_APP_API_AUTHORIZATION_TOKEN,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setConversationId(data.conversation_id);
          addResponseMessage(`Hello, how can I help you?`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.REACT_APP_API_AUTHORIZATION_TOKEN]);

  const handleNewUserMessage = (newMessage: string) => {
    console.log(`New message incoming! ${newMessage}`);
    setUserMessage(newMessage);
  };

  // Handle New User Message
  useEffect(() => {
    if (userMessage) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMessage]);

  // Handle Pending response
  useEffect(() => {
    if (isFetching || isFetched) {
      toggleMsgLoader();
    }
  }, [isFetching, isFetched]);

  // Handle Bot response
  useEffect(() => {
    if (botReply) {
      addResponseMessage(botReply);
    }
  }, [botReply]);

  return (
    <Widget
      handleNewUserMessage={handleNewUserMessage}
      title="Knowledge Center Chatbot"
      subtitle="Visa and Quota Services"
      emojis={false}
    />
  );
}

export default Chat;
