import { chatEndpoint } from "../api/consts";
import { SendMessageProps, SendMessageResponse } from "./Chat.types";

export const sendMessage = async (body: SendMessageProps) => {
    const response = await fetch(chatEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION_TOKEN || '',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data as SendMessageResponse;
  };